import { defineStore } from "pinia";
import {
  ChatState,
  ChatDocument,
  DeleteMessageEntity,
  MessageDocument,
  Log,
  CategorizedLogs,
  LogsByCategory,
  UpdateMessageEntity,
  SuggestionDocument,
  Roles,
  NotificationTypes,
  MessageMetadata,
  MessageParams,
} from "@/types";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import _set from "lodash/set";
import RoleAccess from "@/plugins/RoleAccess";
import { EXCLUDED_SUGGESTION_TRIGGER_TYPES } from "@/types/constants";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";
import { useConfigStore } from "@/stores/config";
import http from "@/services/http";

function sortLogbook(logs: Log[]): Record<string, Log> {
  return Object.entries(logs)
    .sort(([, a], [, b]) => +b.createdAt?.toDate() - +a.createdAt?.toDate())
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {} as Record<string, Log>);
}

function sortLogbooksCategories(logsByCategory: LogsByCategory): LogsByCategory {
  const categoriesWithDatetime = [];
  for (const [category, logs] of Object.entries(logsByCategory)) {
    const sortedLogs = sortLogbook(logs);
    const firstLogId = Object.keys(sortedLogs)[0];
    const lastUpdateAt = sortedLogs[firstLogId].createdAt?.toDate();
    categoriesWithDatetime.push({ category, sortedLogs, lastUpdateAt });
  }
  categoriesWithDatetime.sort((a, b) => +b.lastUpdateAt - +a.lastUpdateAt);
  return categoriesWithDatetime.reduce((acc, item) => (acc = { ...acc, [item.category]: item.sortedLogs }), {});
}

export const useChatStore = defineStore("chat", {
  state: (): ChatState => ({
    claimedChat: null,
    unclaimedChats: [],
    chats: [],
    currentMessages: [],
    categorizedLogs: {
      customer: {},
      entertainmentProfile: {},
    } as CategorizedLogs,
    waitingForClaim: false,
    initialized: false,
    hasBeginningMessage: false,
    isLoadingUnclaimedChats: false,
    suggestions: [],
  }),
  getters: {
    reversedCurrentMessages: (state: ChatState) => {
      return [...state.currentMessages].reverse();
    },
    sortedUnclaimedChats: (state: ChatState) => {
      return [...state.unclaimedChats].sort((a: ChatDocument, b: ChatDocument): number => {
        return Math.sign(a.lastMessage.createdAt.seconds - b.lastMessage.createdAt.seconds);
      });
    },
    sortedCategorizedLogs(state: ChatState) {
      return {
        customer: sortLogbooksCategories(state.categorizedLogs.customer),
        entertainmentProfile: sortLogbooksCategories(state.categorizedLogs.entertainmentProfile),
      };
    },
    isLegacyChat: (state: ChatState) => {
      return state.claimedChat.origin?.type === "legacy";
    },
    countLogbooks: (state: ChatState): { customer: number; entertainmentProfile: number } => {
      const countCustomerLogbooks = state.categorizedLogs?.customer
        ? Object.keys(state.categorizedLogs.customer).reduce((sum, category) => {
            return sum + Object.keys(state.categorizedLogs.customer[category]).length;
          }, 0)
        : 0;

      const countProfileLogbooks = state.categorizedLogs?.entertainmentProfile
        ? Object.keys(state.categorizedLogs.entertainmentProfile).reduce((sum, category) => {
            return sum + Object.keys(state.categorizedLogs.entertainmentProfile[category]).length;
          }, 0)
        : 0;

      return {
        customer: countCustomerLogbooks,
        entertainmentProfile: countProfileLogbooks,
      };
    },
    domainConfiguration: (state: ChatState) => {
      return useConfigStore().domainConfig(state.claimedChat.domain);
    },
    hasSuggestions(state: ChatState): boolean {
      const triggerType = state.claimedChat?.trigger?.type;
      const hasTriggerToDisableSuggestions = triggerType && EXCLUDED_SUGGESTION_TRIGGER_TYPES.has(triggerType);
      return (
        this.domainConfiguration.suggestionsEnabled &&
        RoleAccess.hasAnyRole([Roles.BetaAccess, Roles.ChatSuggestions]) &&
        !RoleAccess.hasRole(Roles.OperatorAgentOnly) &&
        !hasTriggerToDisableSuggestions
      );
    },
  },
  actions: {
    init() {
      this.initialized = true;
    },
    async sendMessage(
      message: MessageParams,
      chatId: Branded<string, "ChatId">,
      domain: string,
      metadata: MessageMetadata,
    ) {
      const messageData = {
        ...message,
        senderType: "agent",
        senderId: useAuthenticatorStore().user.uid as Branded<string, "SenderId">,
      } as MessageDocument;

      // immediately add new message to the messages list to show satisfying feedback to the author
      this.addMessage(messageData);
      await http.post("/message/send", {
        chatDocumentId: chatId,
        message: messageData,
        domain,
        metadata,
      });
    },
    async claimChat(chat: ChatDocument): Promise<void> {
      return await http
        .post(`/chats/${chat.id}/claim`, { domain: chat.domain })
        .then(() => {
          this.waitingForClaim = true;
          this.chatToBeClaimedListener(chat);
        })
        .catch((error) => {
          useNotificationStore().addNotification({
            message: `Failed to claim chat. ${error}`,
            type: NotificationTypes.Error,
          });
        });
    },
    chatToBeClaimedListener(chat: ChatDocument): void {
      firebaseRepoManager.dispatch("chat", chat.locale, "waitForClaim", {
        chatId: chat.id,
      });
    },
    async deleteMessages({ messages, locale }: { messages: DeleteMessageEntity[]; locale: string }): Promise<void> {
      await firebaseRepoManager.dispatch("chat", locale, "deleteMessages", messages);
    },
    async updateMessage({ message, locale }: { message: UpdateMessageEntity; locale: string }): Promise<void> {
      await firebaseRepoManager.dispatch("chat", locale, "updateMessage", message);
    },
    resetChatState(claimedChat: ChatDocument): void {
      this.currentMessages = [];
      this.resetCurrentLogs();
      this.updateClaimedChat(claimedChat, 'removed');
    },
    addSuggestion(value: SuggestionDocument) {
      this.suggestions.push(value);
    },
    updateSuggestion(value: SuggestionDocument) {
      const foundSuggestionIndex = this.suggestions.findIndex((suggestion) => suggestion.id === value.id);
      this.suggestions.splice(foundSuggestionIndex, 1, { ...value, id: value.id });
    },
    removeSuggestion(value: SuggestionDocument) {
      const foundSuggestionIndex = this.suggestions.findIndex((suggestion) => suggestion.id === value.id);
      this.suggestions.splice(foundSuggestionIndex, 1);
    },
    modifyMessage(editedMessage: MessageDocument) {
      const foundMessageToUpdate = this.currentMessages.find(
        (message: MessageDocument) => message.id === editedMessage.id,
      );
      foundMessageToUpdate
        ? Object.assign(foundMessageToUpdate, editedMessage)
        : this.currentMessages.push(editedMessage);
    },
    addMessage(value: MessageDocument) {
      this.currentMessages.unshift(value);
    },
    removeMessage(value: MessageDocument) {
      const index = this.currentMessages.map((doc: any) => doc.id).indexOf(value.id);
      if (index > -1) {
        this.currentMessages.splice(index, 1);
      }
    },
    updateClaimedChat(claimedChat: ChatDocument, type: "added" | "removed" | "modified"): void {  
      switch (type) {
        case 'added':
            this.claimedChat = claimedChat;
            break;
        case 'modified':
            this.claimedChat = { ...this.claimedChat, ...claimedChat };
            break;
        case 'removed':
            if (this.claimedChat && this.claimedChat.id === claimedChat.id) {
                this.claimedChat = null;
            }
            break;
        default:
            throw new Error(`Unknown action: ${type}`);
      }
    },
    updateUnlaimedChat(unclaimedChat: ChatDocument, type: "added" | "removed" | "modified"): void {  
      const foundChatToUnclaim = this.unclaimedChats.find((obj: ChatDocument) => obj.id === unclaimedChat.id);
      const unclaimedChatIndex = this.unclaimedChats.findIndex((doc: ChatDocument) => doc.id === unclaimedChat.id);
      switch (type) {
        case 'added':
            this.unclaimedChats.push(unclaimedChat);
            break;
        case 'modified':
            if (foundChatToUnclaim) {
                Object.assign(foundChatToUnclaim, unclaimedChat);
            } else {
                this.unclaimedChats.push(unclaimedChat);
            }
            break;
        case 'removed':
            if (unclaimedChatIndex > -1) {
                this.unclaimedChats.splice(unclaimedChatIndex, 1);
            }
            break;
        default:
            throw new Error(`Unknown action: ${type}`);
      }
    },
    resetCurrentLogs() {
      this.categorizedLogs = {
        customer: {},
        entertainmentProfile: {},
      };
    },
    setLog(log: Log) {
      const concerning = log.concerning;
      const category = log.category || "other";
      const id = log.id;

      _set(this.categorizedLogs, [concerning, category, id], { ...log });
      this.categorizedLogs = { ...this.categorizedLogs };
    },
  },
});
